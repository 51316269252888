import React, { useState, useEffect, Fragment } from 'react'
import FooterInfoLayout from '@layouts/footer-info-layout'
import { FooterInfo } from '@common/interface/footer'
import parse from 'html-react-parser'
import { Text, VStack } from '@chakra-ui/react'

interface FooterDetailProps {
  info: FooterInfo | null
  infos: FooterInfo[]
}

const FooterDetail: React.FC<FooterDetailProps> = ({ info, infos }) => {
  const [anchors, setAnchors] = useState<FooterInfo[]>([])

  useEffect(() => {
    if (info != null && infos != null) {
      const a = infos
        .filter((i) => i.page?.id === info.id)
        .sort((i1, i2) => i1.anchor_position - i2.anchor_position)
      setAnchors([...a])
    }
  }, [info, infos])

  return (
    <FooterInfoLayout title={info?.title as string}>
      {
        <Fragment>
          <VStack w={'100%'} alignItems={'flex-start'}>
            <Text textStyle="tcText">{parse(info?.content || '')}</Text>
          </VStack>
          {anchors.map((a) => {
            return (
              <VStack
                key={a.id}
                w={'100%'}
                alignItems={'flex-start'}
                id={`${a.handle}`}
              >
                {a.header_content && (
                  <Text textStyle="tcTitle" w={'100%'}>
                    {a.header_content}
                  </Text>
                )}
                <Text textStyle="tcText">{parse(a?.content || '')}</Text>
              </VStack>
            )
          })}
        </Fragment>
      }
    </FooterInfoLayout>
  )
}

export default FooterDetail
