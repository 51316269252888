import React from 'react'
import { Container, StackProps, VStack } from '@chakra-ui/react'
import HeadingTag from '@components/heading-tag'

interface FooterInfoLayoutPropsType {
  title: string
  children: React.ReactNode
  vstack?: StackProps
}

const FooterInfoLayout: React.FC<FooterInfoLayoutPropsType> = ({
  title,
  children,
  vstack
}) => {
  return (
    <Container maxW={'1350px'} my={28}>
      <VStack spacing={8} {...vstack}>
        <HeadingTag>{title}</HeadingTag>
        {children}
      </VStack>
    </Container>
  )
}

export default FooterInfoLayout
