import React, { ReactElement, useEffect, useState } from 'react'
import MainLayout from '@layouts/main-layout'
import { useFooter } from '@lib/hooks/use-footer'
import { useRouter } from 'next/router'
import FooterDetail from '@components/footer/footer-detail'
import { FooterInfo } from '@common/interface/footer'

const FooterPage = () => {
  const { infos } = useFooter()
  const router = useRouter()
  const [info, setInfo] = useState<FooterInfo | null>(null)

  useEffect(() => {
    if (infos.length > 0) {
      const pathName = router.asPath.replace('/', '').split('#')[0]
      const info = infos.find((i) => i.handle === pathName)
      if (info == null && pathName !== '[handle]') {
        router.push('/404')
      } else if (info != null) {
        setInfo(info)
      }
    }
  }, [infos, router])

  return <FooterDetail infos={infos} info={info} />
}

FooterPage.getLayout = (page: ReactElement) => {
  return <MainLayout>{page}</MainLayout>
}

export default FooterPage
